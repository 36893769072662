export const HEADERS = [
  'Folio',
  'Encargado de la descarga',
  'Tienda',
  'Peso después de la descarga',
  'Registro Foto',
  'Hora de descarga',
  'Fecha de registro',
  'Comentarios',
];
