export const HEADERS = [
  'Tienda',
  'Solicitante',
  'Folio',
  'Refacción',
  'Equipo',
  'Encargado de la tienda',
  'Proveedor',
  'Fecha de registro',
  'Fecha de solicitud',
  'Fecha de autorización',
  'Fecha de recepción',
  'Fecha de instalación',
  'Hoja de servicio',
];
